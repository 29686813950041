import { getClient } from "@/api/AxiosClient";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { JOB_APPLICATION_WORKFLOW_WPID } from "@cloud/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon, StarIcon } from "@radix-ui/react-icons";
import { Separator } from "@radix-ui/react-select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { JobsRunHistory } from "./JobsRunHistory";
import { useApiCredential } from "@/hooks/useApiCredential";
import axios from "axios";
import { apiBaseUrl } from "@/util/env";
import { useState } from "react";
import { JobAgentFileUpload } from "./JobAgentFileUpload";
import { toast } from "@/components/ui/use-toast";

const urlPlaceholder = `https://jobs.lever.co/...\nhttps://boards.greenhouse.io/...`;
const additionalInformationPlaceholder = `Yes, I am authorized to work in the U.S\nI do not have any security clearances\nExpected Salary: 90,000`;

const FAST_QUEUE_TASK_COUNT_THRESHOLD = 5;

function getTrimmedLines(str: string): Array<string> {
  return str.split("\n").map((line) => line.trim());
}

const jobsFormSchema = z.object({
  jobUrls: z.string().min(1, {
    message: "At least one site URL is required",
  }),
  fileUrl: z
    .string()
    .or(z.null())
    .or(
      z.object({
        s3uri: z.string(),
        presignedUrl: z.string(),
      }),
    ),
  additionalInformation: z.string(),
});

const jobsAgentClient = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

type JobsFormValues = z.infer<typeof jobsFormSchema>;

function JobsPage() {
  const apiKey = useApiCredential();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const credentialGetter = useCredentialGetter();
  const form = useForm<JobsFormValues>({
    resolver: zodResolver(jobsFormSchema),
    defaultValues: {
      jobUrls: "",
      fileUrl: null,
      additionalInformation: "",
    },
  });

  const runJobAgentBatchQueueMutation = useMutation({
    mutationFn: async (values: JobsFormValues) => {
      if (!apiKey) {
        return;
      }
      const searchParams = new URLSearchParams();
      searchParams.set("template", "true");
      const fileUrl =
        typeof values.fileUrl === "object" && values.fileUrl !== null
          ? values.fileUrl.s3uri
          : values.fileUrl;
      jobsAgentClient.post(
        `/workflows/${JOB_APPLICATION_WORKFLOW_WPID}/run`,
        {
          data: {
            resume: fileUrl,
            job_urls: values.jobUrls.split("\n"),
            additional_information: values.additionalInformation,
          },
        },
        {
          params: searchParams,
          headers: {
            "x-api-key": apiKey,
          },
        },
      );
    },
  });

  const runJobAgentFastQueueMutation = useMutation({
    mutationFn: async (values: JobsFormValues) => {
      const client = await getClient(credentialGetter);
      const searchParams = new URLSearchParams();
      searchParams.set("template", "true");
      const fileUrl =
        typeof values.fileUrl === "object" && values.fileUrl !== null
          ? values.fileUrl.s3uri
          : values.fileUrl;
      client.post(
        `/workflows/${JOB_APPLICATION_WORKFLOW_WPID}/run`,
        {
          data: {
            resume: fileUrl,
            job_urls: values.jobUrls.split("\n"),
            additional_information: values.additionalInformation,
          },
        },
        {
          params: searchParams,
        },
      );
    },
  });

  async function onSubmit(values: JobsFormValues) {
    const { additionalInformation, fileUrl, jobUrls } = values;
    const parsedUrls = getTrimmedLines(jobUrls);
    const fastQueueUrls = parsedUrls.slice(0, FAST_QUEUE_TASK_COUNT_THRESHOLD);
    const batchQueueUrls = parsedUrls.slice(FAST_QUEUE_TASK_COUNT_THRESHOLD);

    const batchQueuePromises = batchQueueUrls.map((url) => {
      return runJobAgentBatchQueueMutation.mutateAsync({
        jobUrls: url,
        additionalInformation,
        fileUrl,
      });
    });

    const fastQueuePromises = fastQueueUrls.map((url) => {
      return runJobAgentFastQueueMutation.mutateAsync({
        jobUrls: url,
        additionalInformation,
        fileUrl,
      });
    });

    setIsLoading(true);

    const settledFastQueuePromises =
      await Promise.allSettled(fastQueuePromises);
    const fulfilledFastQueuePromises = settledFastQueuePromises.filter(
      (promise) => promise.status === "fulfilled",
    );
    const rejectedFastQueuePromises = settledFastQueuePromises.filter(
      (promise) => promise.status === "rejected",
    );

    const settledBatchQueuePromises =
      await Promise.allSettled(batchQueuePromises);
    const fulfilledBatchQueuePromises = settledBatchQueuePromises.filter(
      (promise) => promise.status === "fulfilled",
    );
    const rejectedBatchQueuePromises = settledBatchQueuePromises.filter(
      (promise) => promise.status === "rejected",
    );

    if (
      fulfilledBatchQueuePromises.length > 0 ||
      fulfilledFastQueuePromises.length > 0
    ) {
      const n =
        fulfilledBatchQueuePromises.length + fulfilledFastQueuePromises.length;
      const plural = n > 1;
      toast({
        title: "Runs started successfully",
        description: `Started applying for ${n} job${plural ? "s" : ""}`,
        variant: "success",
      });
    }

    if (
      rejectedBatchQueuePromises.length > 0 ||
      rejectedFastQueuePromises.length > 0
    ) {
      const n =
        rejectedBatchQueuePromises.length + rejectedFastQueuePromises.length;
      const plural = n > 1;
      toast({
        title: "Runs failed to start",
        description: `Could not start applying for ${n} job${plural ? "s" : ""}`,
        variant: "destructive",
      });
    }

    // stupid temporary hack
    setTimeout(() => {
      queryClient.invalidateQueries({
        queryKey: ["skyvernJobsWorkflows"],
      });

      setIsLoading(false);
    }, 1000);
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl">Jobs</h1>
      <section className="flex items-stretch gap-8 bg-slate-elevation1 p-6">
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-6 py-6">
                <FormField
                  control={form.control}
                  name="fileUrl"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">
                            Upload Resume
                          </FormLabel>
                          <FormControl>
                            <JobAgentFileUpload
                              value={field.value}
                              onChange={field.onChange}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="jobUrls"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">
                            Application URLs
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              placeholder={urlPlaceholder}
                              rows={3}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="additionalInformation"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">
                            Additional Information
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              placeholder={additionalInformationPlaceholder}
                              rows={3}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
              </div>
              <div className="flex justify-end">
                <Button type="submit" className="w-24" disabled={isLoading}>
                  {isLoading && (
                    <ReloadIcon className="mr-2 size-4 animate-spin" />
                  )}
                  Run
                </Button>
              </div>
            </form>
          </Form>
        </div>
        <div className="flex w-[448px] shrink-0 flex-col gap-6 rounded-lg bg-slate-elevation2 p-6">
          <h2 className="text-lg font-bold">Job Application Agent</h2>
          <h3>
            Skyvern can navigate to any website and fill out the job application
            the information from your resume
          </h3>
          <Separator />
          <div className="space-y-4">
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">Upload your resume</div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Add your job urls as a line separated list
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Additional information section is used to answer any questions
                not answered by your resume
              </div>
            </div>
          </div>
        </div>
      </section>
      <JobsRunHistory />
    </div>
  );
}

export { JobsPage };
