import { getClient } from "@/api/AxiosClient";
import { Status, TaskApiResponse } from "@/api/types";
import { StatusFilterDropdown } from "@/components/StatusFilterDropdown";
import { Button } from "@/components/ui/button";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { WorkflowBlockCollapsibleContent } from "@/routes/workflows/WorkflowBlockCollapsibleContent";
import { downloadBlob } from "@/util/downloadBlob";
import { cn } from "@/util/utils";
import { DownloadIcon, ReloadIcon } from "@radix-ui/react-icons";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function FormsRunHistory() {
  const credentialGetter = useCredentialGetter();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const navigate = useNavigate();
  const [statusFilters, setStatusFilters] = useState<Array<Status>>([]);

  const skeleton = [1, 2, 3, 4, 5].map((_, index) => {
    return (
      <TableRow key={index}>
        <TableCell className="w-1/3">
          <Skeleton className="h-6 w-full" />
        </TableCell>
        <TableCell className="w-1/4">
          <Skeleton className="h-6 w-full" />
        </TableCell>
        <TableCell className="w-1/3">
          <Skeleton className="h-6 w-full" />
        </TableCell>
        <TableCell className="w-1/12">
          <Skeleton className="h-6 w-full" />
        </TableCell>
      </TableRow>
    );
  });

  const {
    data: tasks,
    isFetching,
    isLoading,
    isError,
    error,
  } = useQuery<Array<TaskApiResponse>>({
    queryKey: ["skyvernFormsTasks", { statusFilters }, page],
    queryFn: async () => {
      const client = await getClient(credentialGetter);

      const params = new URLSearchParams();
      params.append("page", String(page));
      statusFilters.forEach((status) => {
        params.append("task_status", status);
      });
      params.append("application", "forms");
      params.append("only_standalone_tasks", "true");

      return client
        .get("/tasks", {
          params,
        })
        .then((response) => response.data);
    },
    refetchOnMount: "always",
    refetchInterval: 10000,
    placeholderData: keepPreviousData,
  });

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }

  function handleNavigate(event: React.MouseEvent, id: string) {
    if (event.ctrlKey || event.metaKey) {
      window.open(
        window.location.origin + `/tasks/${id}/actions`,
        "_blank",
        "noopener,noreferrer",
      );
    } else {
      navigate(`/tasks/${id}/actions`);
    }
  }

  function handleExport() {
    if (!tasks) {
      return; // should never happen
    }
    const data = ["id,url,status,created,failure_reason"];
    tasks.forEach((task) => {
      const row = [
        task.task_id,
        task.request.url,
        task.status,
        task.created_at,
        task.failure_reason ?? "",
      ];
      data.push(
        row
          .map(String) // convert every value to String
          .map((v) => v.replace(new RegExp('"', "g"), '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
      );
    });
    const contents = data.join("\r\n");

    downloadBlob(contents, "export.csv", "data:text/csv;charset=utf-8;");
  }

  return (
    <div className="space-y-4">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl">Run History</h1>
          {isFetching && <ReloadIcon className="size-4 animate-spin" />}
        </div>
        <div className="flex gap-2">
          <StatusFilterDropdown
            values={statusFilters}
            onChange={setStatusFilters}
          />
          <Button variant="secondary" onClick={handleExport}>
            <DownloadIcon className="mr-2" />
            Export CSV
          </Button>
        </div>
      </header>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="rounded-t-md bg-slate-elevation2">
            <TableRow>
              <TableHead className="w-10 rounded-tl-md"></TableHead>
              <TableHead className="w-1/5 text-slate-400">Task Title</TableHead>
              <TableHead className="w-1/6 text-slate-400">ID</TableHead>
              <TableHead className="w-1/4 text-slate-400">URL</TableHead>
              <TableHead className="w-1/8 text-slate-400">Status</TableHead>
              <TableHead className="w-1/5 rounded-tr-md text-slate-400">
                Created At
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              skeleton
            ) : tasks?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>Could not find any tasks</TableCell>
              </TableRow>
            ) : (
              tasks?.map((task) => {
                return (
                  <WorkflowBlockCollapsibleContent
                    key={task.task_id}
                    task={task}
                    onNavigate={handleNavigate}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
        <Pagination className="pt-2">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className={cn({ "cursor-not-allowed": page === 1 })}
                onClick={() => {
                  if (page === 1) {
                    return;
                  }
                  const params = new URLSearchParams();
                  params.set("page", String(Math.max(1, page - 1)));
                  setSearchParams(params, { replace: true });
                }}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink>{page}</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                onClick={() => {
                  const params = new URLSearchParams();
                  params.set("page", String(page + 1));
                  setSearchParams(params, { replace: true });
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </div>
  );
}

export { FormsRunHistory };
