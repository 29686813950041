import { getClient } from "@/api/AxiosClient";
import { Status, WorkflowRunApiResponse } from "@/api/types";
import { StatusBadge } from "@/components/StatusBadge";
import { StatusFilterDropdown } from "@/components/StatusFilterDropdown";
import { Button } from "@/components/ui/button";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { downloadBlob } from "@/util/downloadBlob";
import { basicLocalTimeFormat, basicTimeFormat } from "@/util/timeFormat";
import { cn } from "@/util/utils";
import { JOB_APPLICATION_WORKFLOW_WPID } from "@cloud/constants";
import { DownloadIcon, ReloadIcon } from "@radix-ui/react-icons";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function JobsRunHistory() {
  const credentialGetter = useCredentialGetter();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const navigate = useNavigate();
  const [statusFilters, setStatusFilters] = useState<Array<Status>>([]);

  const skeleton = [1, 2, 3, 4, 5].map((_, index) => {
    return (
      <TableRow key={index}>
        <TableCell className="w-1/4">
          <Skeleton className="h-6 w-full" />
        </TableCell>
        <TableCell className="w-1/4">
          <Skeleton className="h-6 w-full" />
        </TableCell>
        <TableCell className="w-1/4">
          <Skeleton className="h-6 w-full" />
        </TableCell>
        <TableCell className="w-1/4">
          <Skeleton className="h-6 w-full" />
        </TableCell>
      </TableRow>
    );
  });

  const {
    data: workflowRuns,
    isFetching,
    isLoading,
    isError,
    error,
  } = useQuery<Array<WorkflowRunApiResponse>>({
    queryKey: ["skyvernJobsWorkflows", { statusFilters }, page],
    queryFn: async () => {
      const client = await getClient(credentialGetter);

      const params = new URLSearchParams();
      params.append("template", "true");
      params.append("page", String(page));
      statusFilters.forEach((status) => {
        params.append("task_status", status);
      });

      return client
        .get(`/workflows/${JOB_APPLICATION_WORKFLOW_WPID}/runs`, {
          params,
        })
        .then((response) => response.data);
    },
    refetchOnMount: "always",
    refetchInterval: 10000,
    placeholderData: keepPreviousData,
  });

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }

  function handleNavigate(event: React.MouseEvent, id: string) {
    if (event.ctrlKey || event.metaKey) {
      window.open(
        window.location.origin +
          `/workflows/${JOB_APPLICATION_WORKFLOW_WPID}/${id}/overview`,
        "_blank",
        "noopener,noreferrer",
      );
    } else {
      navigate(`/workflows/${JOB_APPLICATION_WORKFLOW_WPID}/${id}/overview`);
    }
  }

  function handleExport() {
    if (!workflowRuns) {
      return; // should never happen
    }
    const data = ["id,status,failure_reason"];
    workflowRuns.forEach((workflowRun) => {
      const row = [
        workflowRun.workflow_run_id,
        workflowRun.status,
        workflowRun.failure_reason,
      ];
      data.push(
        row
          .map(String) // convert every value to String
          .map((v) => v.replace(new RegExp('"', "g"), '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
      );
    });
    const contents = data.join("\r\n");

    downloadBlob(contents, "export.csv", "data:text/csv;charset=utf-8;");
  }

  return (
    <div className="space-y-4">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl">Run History</h1>
          {isFetching && <ReloadIcon className="size-4 animate-spin" />}
        </div>
        <div className="flex gap-2">
          <StatusFilterDropdown
            values={statusFilters}
            onChange={setStatusFilters}
          />
          <Button variant="secondary" onClick={handleExport}>
            <DownloadIcon className="mr-2" />
            Export CSV
          </Button>
        </div>
      </header>
      <div className="rounded-md border">
        <Table>
          <TableHeader className="rounded-t-md bg-slate-elevation2">
            <TableRow>
              <TableHead className="w-1/3 text-slate-400">ID</TableHead>
              <TableHead className="w-1/3 text-slate-400">Status</TableHead>
              <TableHead className="w-1/3 rounded-tr-md text-slate-400">
                Created At
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              skeleton
            ) : workflowRuns?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3}>Could not find any runs</TableCell>
              </TableRow>
            ) : (
              workflowRuns?.map((workflowRun) => {
                return (
                  <TableRow
                    key={workflowRun.workflow_run_id}
                    className="cursor-pointer"
                    onClick={(event) => {
                      handleNavigate(event, workflowRun.workflow_run_id);
                    }}
                  >
                    <TableCell>{workflowRun.workflow_run_id}</TableCell>
                    <TableCell>
                      <StatusBadge status={workflowRun.status} />
                    </TableCell>
                    <TableCell
                      className="w-1/3"
                      title={basicTimeFormat(workflowRun.created_at)}
                    >
                      {basicLocalTimeFormat(workflowRun.created_at)}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <Pagination className="pt-2">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className={cn({ "cursor-not-allowed": page === 1 })}
                onClick={() => {
                  if (page === 1) {
                    return;
                  }
                  const params = new URLSearchParams();
                  params.set("page", String(Math.max(1, page - 1)));
                  setSearchParams(params, { replace: true });
                }}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink>{page}</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                onClick={() => {
                  const params = new URLSearchParams();
                  params.set("page", String(page + 1));
                  setSearchParams(params, { replace: true });
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </div>
  );
}

export { JobsRunHistory };
